@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-size: 14px;
	line-height: 20px;
}
.page {
	@apply flex flex-col justify-between;
}
.page > div {
	@apply px-7 py-5;
}

/* Select Page Components Carousel Style */
.swiper-button-prev::after,
.swiper-button-next::after {
	color: #1c1c1c !important; /* Change the color of the arrows */
	font-size: 16px !important;
	@apply hidden/* Change the size of the arrows */;
}

/* Change the background color and size of the arrow container */
.swiper-button-prev,
.swiper-button-next {
	/* Change the height of the arrows container */
}
